<template>
  <div>
    <template v-if="loading || loadingDriversVehicles">
      <v-layout
        column
        justify-space-around
        style="background-color: white; height: 300px"
      >
        <v-progress-circular
          style="margin: 0 auto"
          :size="70"
          indeterminate
          color="primary"
        />
      </v-layout>
    </template>
    <div v-else-if="reservation && trip" class="dispatch-sidebar" :title="null">
      <!-- START CONFLICT SCREEN -->
      <div
        v-show="showConflictDetail"
        class="dispatch-sidebar--conflict-wrapper"
      >
        <div style="max-width: 435px">
          <div class="dispatch-sidebar--conflict-arrow">
            <a class="arrow_back" @click="setShowConflictDetail(false)">
              <CRIcon view-box="0 0 24 24" :width="20" :height="20">
                arrow_back
              </CRIcon>
              Back To Reservation Assignment
            </a>
          </div>
          <div
            v-for="(conflict, i) in conflicts"
            :key="`conflict-${i}-${conflict.tripAssignmentId || ''}`"
            class="dispatch-sidebar--conflict-box"
          >
            <v-tooltip bottom>
              <template #activator="{ on }">
                <h4 class="mb-2" style="line-height: 1.2" v-on="on">
                  {{ conflict.name }}
                  <router-link
                    :id="`conflict-${i}-reservation-detail-link`"
                    :to="{
                      path: `/reservations/${conflict.conflictingReservationId}`,
                    }"
                    target="_blank"
                    @click.stop="() => {}"
                  >
                    (Res ID: {{ conflict.conflictingReservationManagedId }})
                  </router-link>
                </h4>
              </template>
              <span>
                Customer: {{ conflict.customerName
                }}{{ conflict.customerAccountName ? ', ' : '' }}
                {{ conflict.customerAccountName }}
              </span>
            </v-tooltip>
            <div class="dispatch-sidebar--conflict-details">
              <template v-if="conflict.conflictType === 'hard'">
                <CRIcon :width="24" :height="24" material color="error">
                  error_color
                </CRIcon>
              </template>
              <template v-else>
                <CRIcon :width="24" :height="24" material color="yellow">
                  error_color
                </CRIcon>
              </template>
              <div>
                <div class="dispatch-sidebar--conflict-description-header">
                  <template v-if="conflict.conflictType === 'hard'">
                    Hard Conflict
                  </template>
                  <template v-else>Potential Conflict</template>
                  - {{ conflict.conflictHeader }}
                </div>
                <p>{{ conflict.description }}</p>
              </div>
            </div>
            <div
              v-if="i < conflicts.length - 1"
              class="dispatch-sidebar--assignment-divider"
              style="margin-top: 40px"
            ></div>
          </div>
        </div>
      </div>

      <!-- START CONFIRMATION DETAIL -->
      <div
        v-show="showConfirmationDetail"
        class="dispatch-sidebar--confirmation-wrapper"
      >
        <div class="dispatch-sidebar--confirmation-content">
          <div class="dispatch-sidebar--error-box">
            <v-layout
              row
              align-center
              class="dispatch-sidebar--error-box-content"
            >
              <v-flex xs3>
                <CRIcon
                  material
                  :width="40"
                  :height="40"
                  color="error"
                  class="icon-error"
                >
                  error_color
                </CRIcon>
              </v-flex>
              <v-flex>
                <p>
                  Updating this reservation with your selected criteria would
                  cause the hard conflicts below. Are you sure you want to
                  proceed?
                </p>
              </v-flex>
            </v-layout>
          </div>
          <div
            v-for="(conflict, i) in conflicts"
            :key="`conflict-${i}-${conflict.tripAssignmentId || ''}`"
            class="dispatch-sidebar--confirmation-conflict-box"
          >
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-layout column v-on="on">
                  <v-layout row justify-start>
                    <v-flex style="margin-left: 10px">
                      <h4 class="mb-2" style="line-height: 1.2">
                        {{ conflict.name }}
                        <router-link
                          :id="`conflict-${i}-reservation-detail-link`"
                          :to="{
                            path: `/reservations/${conflict.conflictingReservationId}`,
                          }"
                          target="_blank"
                          @click.stop="() => {}"
                        >
                          (Res ID:
                          {{ conflict.conflictingReservationManagedId }})
                        </router-link>
                      </h4>
                    </v-flex>
                  </v-layout>
                  <v-layout row>
                    <v-flex xs3>
                      <v-layout row justify-center>
                        <CRIcon
                          :width="24"
                          :height="24"
                          style="margin-left: -5px"
                          material
                          class="icon-error"
                        >
                          error_color
                        </CRIcon>
                      </v-layout>
                    </v-flex>
                    <v-flex>
                      <div
                        class="dispatch-sidebar--confirmation-description-header"
                      >
                        Hard Conflict - {{ conflict.conflictHeader }}
                      </div>
                      <p>{{ conflict.description }}</p>
                    </v-flex>
                  </v-layout>
                </v-layout>
              </template>
              <span>
                Customer: {{ conflict.customerName
                }}{{ conflict.customerAccountName ? ', ' : '' }}
                {{ conflict.customerAccountName }}
              </span>
            </v-tooltip>
            <div
              v-if="i < conflicts.length - 1"
              class="dispatch-sidebar--assignment-divider"
              style="margin-top: 40px"
            ></div>
          </div>
        </div>
        <div class="cr-sidebar-dialog--button-spacer"></div>
        <div class="cr-sidebar-dialog--button-spacer"></div>
        <CRButton
          id="dispatch-sidebar-go-back-btn"
          class="dispatch-sidebar--action-btn dispatch-sidebar--confirmation-cancel"
          color="white"
          @click="displayReservationInfo"
        >
          <span class="primary-text">No, Go Back</span>
        </CRButton>
        <CRButton
          id="dispatch-sidebar-update-res-conflicts-btn"
          class="dispatch-sidebar--action-btn dispatch-sidebar--confirmation-submit"
          color="error"
          @click="commitReservationUpdates"
        >
          Yes, Update Reservation With Conflicts
        </CRButton>
      </div>

      <!-- START DRIVER INFO -->
      <ReservationSendDriverInfo
        v-show="showSendDriverInfo"
        :reservation-id="parentReservationId || reservation.reservationId"
        :customer-email="reservationCompanyEmail"
        :trip-contact-email="reservationTripContactEmail"
      />

      <!-- START STOP NOTE -->
      <StopInfoSidebar
        v-if="showAddStopNote"
        :reservation-id="reservation.reservationId"
        :reservation-type="reservation.reservationType"
      />

      <!-- START CONTACT NOTE -->
      <AddContactNote
        v-if="showAddContactNote"
        :customer-id="reservation.trip.customerId"
        :active-customer="reservation.trip.customer"
      />

      <!-- START TRIP CONTACT NOTE -->
      <AddContactNote
        v-if="showAddTripContactNote"
        :mode="`tripContact`"
        :customer-id="reservation.trip.tripContact.customerId"
        :active-customer="reservation.trip.tripContact"
      />

      <!-- START TRIP NOTES -->
      <ReservationAddTripNotes
        v-if="showAddTripNotes"
        :reservation-id="reservation.reservationId"
        :trip="reservation.trip"
        :reservation-type="reservation.reservationType"
      />

      <!-- START TRIP CONTACT -->
      <TripContactSidebar
        v-if="showAddTripContact"
        :trip-id="reservation.trip.tripId"
        :value="
          reservation.trip.tripContact === null
            ? {}
            : reservation.trip.tripContact
        "
      />

      <!-- START RESERVATION INFO -->
      <div
        v-show="showReservationDetail"
        class="dispatch-sidebar--reservation-info"
      >
        <div class="dispatch-sidebar--reservation-info-content">
          <div
            v-if="!isAssignmentsOnly && trip"
            class="dispatch-sidebar--info-top"
          >
            <v-layout row>
              <v-flex xs6>
                <div v-if="reservation" class="dispatch-sidebar--header-small">
                  Status
                </div>
                <p>{{ toTitleCase(reservation.reservationStatus) }}</p>
              </v-flex>
              <v-flex xs6>
                <div class="dispatch-sidebar--header-small">Booked On</div>
                <p>{{ bookedOnDate }}</p>
              </v-flex>
            </v-layout>
            <v-layout row style="margin-top: 10px">
              <v-flex xs6>
                <div v-if="reservation" class="dispatch-sidebar--header-small">
                  Trip Type
                </div>
                <p>{{ trip.tripType.label || '' }}</p>
              </v-flex>
              <v-flex xs6>
                <div v-if="reservation" class="dispatch-sidebar--header-small">
                  Passenger Count
                </div>
                <p>{{ trip.passengerCount || '' }}</p>
              </v-flex>
            </v-layout>
            <v-layout v-if="trip.tripContact" row style="margin-top: 10px">
              <v-flex xs6>
                <div v-if="reservation" class="dispatch-sidebar--header-small">
                  Trip Contact
                  <span class="edit-button" @click="clickTripContact">
                    Edit
                  </span>
                  <!--here-->
                </div>
                <p>
                  {{
                    `${trip.tripContact.firstName} ${trip.tripContact.lastName}` ||
                    ''
                  }}
                </p>
                <p style="margin-left: -2px">
                  {{ trip.tripContact.email || '' }}
                </p>
                <p style="margin-left: -2px">
                  {{ trip.tripContact.phone || '' }}
                </p>
              </v-flex>
              <v-flex v-if="reservation && trip.tripContact.notes" xs6>
                <div class="dispatch-sidebar--header-small">
                  Contact Note
                  <span class="edit-button" @click="clickTripContactNote">
                    Edit
                  </span>
                </div>
                <p>{{ trip.tripContact.notes || '' }}</p>
              </v-flex>
              <v-flex v-else xs6>
                <v-btn
                  :id="`user-selector-button-add-trip-contact-notes`"
                  color="primary"
                  style="margin: 0 0 0 -6px"
                  flat
                  small
                  @click="clickTripContactNote"
                >
                  Add Contact Note
                </v-btn>
              </v-flex>
            </v-layout>
            <v-layout v-else row style="margin-top: 10px">
              <span
                style="margin-left: -2px"
                class="edit-button"
                @click="clickTripContact"
              >
                Add Trip Contact
              </span>
            </v-layout>
          </div>
          <!-- START ASSIGNMENTS  -->
          <div
            class="dispatch-sidebar--assignments"
            :style="`background: white`"
          >
            <v-layout row justify-space-between>
              <h4 v-if="!isAssignmentsOnly">Assignments</h4>
              <span
                v-show="validateTripLoading.validateTripLoading"
                class="validate-trip-loading"
              >
                <div class="dot-typing"></div>
                <span>Checking for conflicts</span>
              </span>
            </v-layout>
            <DispatchReservationSidebarAssignments
              :drivers="drivers"
              :vehicles="vehicles"
              :available-drivers="availableDrivers"
              :available-vehicles="availableVehicles"
              :reservation="reservation"
              :model="model"
              @refresh="$emit('refresh')"
              @update-assignment="updateModel($event)"
            />
          </div>
          <!-- START TRIP TOTAL -->
          <div v-if="!isAssignmentsOnly" class="dispatch-sidebar--trip-total">
            <h4>Trip Total</h4>
            <v-layout row align-end>
              <v-flex>
                <div class="dispatch-sidebar--gray-text">Amount:</div>
                <div class="dispatch-sidebar--gray-text">Balance:</div>
              </v-flex>
              <v-flex>
                <div>{{ currencyFilter(reservation.amount) }}</div>
                <div>{{ currencyFilter(reservation.balance) }}</div>
              </v-flex>
              <v-layout
                v-if="reservation.dueDate && !balanceIsPaid"
                row
                align-center
              >
                <span
                  :class="dueDateError ? 'dispatch-sidebar--error-text' : ''"
                >
                  {{ isoToSimpleString(reservation.dueDate) }}
                </span>
                <span
                  :class="`dispatch-sidebar--due-date ${
                    dueDateError ? 'dispatch-sidebar--due-date-past' : ''
                  }`"
                >
                  {{ dueDateError ? 'Past Due' : 'Due' }}
                </span>
              </v-layout>
            </v-layout>
          </div>
          <!-- START CUSTOMER INFO -->
          <div
            v-if="
              !isAssignmentsOnly && reservationIsNotReferral && reservation.trip
            "
            class="dispatch-sidebar--customer-info"
          >
            <h4>Customer Information</h4>
            <a
              target="_blank"
              class="sidebar-link"
              :href="
                $router.resolve({
                  name: 'customers.details',
                  params: {
                    id: reservation.trip.customerId,
                  },
                }).href
              "
            >
              <div class="dispatch-sidebar--customer-info-box">
                <v-layout row>
                  <v-flex>
                    <CRIcon
                      :width="65"
                      :height="65"
                      material
                      color="grayMidLight"
                    >
                      account_circle
                    </CRIcon>
                  </v-flex>
                  <v-flex v-if="reservation && reservation.trip">
                    <b>
                      {{
                        `${reservation.trip.customer.firstName} ${reservation.trip.customer.lastName}`
                      }}
                    </b>
                    <p>{{ reservation.trip.customer.email }}</p>
                    <p>
                      {{
                        phoneFormatFilter(reservation.trip.customer.phone || '')
                      }}
                    </p>
                  </v-flex>
                </v-layout>
              </div>
            </a>
            <v-flex xs12 style="min-height: 25px; margin-top: 10px">
              <v-flex xs4>
                <v-btn
                  v-if="!customerNotesExist"
                  :id="`user-selector-button-add-customer-notes`"
                  color="primary"
                  style="margin: 0 0 0 -6px"
                  flat
                  small
                  @click="clickContactNote"
                >
                  Add Contact Note
                </v-btn>
              </v-flex>
              <v-flex v-if="customerNotesExist" xs12>
                <p>
                  <b>Contact Note</b>
                  <span class="edit-button" @click="clickContactNote">
                    Edit
                  </span>
                </p>
                <div style="overflow-wrap: break-word">
                  <p
                    v-if="
                      reservation.trip.customer.customerNotes &&
                      reservation.trip.customer.customerNotes[0]
                    "
                    v-html="
                      reservation.trip.customer.customerNotes[0].html ||
                      reservation.trip.customer.customerNotes[0].note
                    "
                  />
                  <p v-else>{{ reservation.trip.customer.notes }}</p>
                </div>
              </v-flex>
            </v-flex>
          </div>
        </div>
        <div class="cr-sidebar-dialog--button-spacer"></div>
        <CRButton
          id="dispatch-sidebar-update-reservation-btn"
          class="dispatch-sidebar--action-btn"
          color="primary"
          :disabled="reservationButtonIsDisabled"
          :loading="assignmentsLoading"
          @click="validateReservationUpdates"
        >
          Update Reservation
        </CRButton>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getAvailableDriversForRes,
  getAvailableVehiclesForRes,
  getUnavailableDriversForRes,
  getUnavailableVehiclesForRes,
  deleteTripAssignmentsWithIds,
  createTripAssignment,
  validateTrip,
  sendDriverInfoNotifications,
} from '@/services/reservations'
import { DateTime } from 'luxon'
import { phoneFormatFilter } from '@/utils/phone'
import { currencyFilter } from '@/utils/currency'
import { authComputed } from '@/state/helpers'
import { EventBus } from '@/utils/event-bus'
import DispatchReservationSidebarAssignments from '@/components/DispatchReservationSidebarAssignments'
import ReservationSendDriverInfo from '@/components/ReservationSendDriverInfo'
import StopInfoSidebar from '@/components/StopInfoSidebar'
import AddContactNote from '@/components/AddContactNote'
import ReservationAddTripNotes from '@/components/ReservationAddTripNotes'
import TripContactSidebar from '@/components/TripContactSidebar.vue'
import { mapActions, mapGetters } from 'vuex'
import { deepClone } from '@/utils/deepClone'
import { buildModel } from '@/utils/tripAssignments'
import { toTitleCase } from '@/utils/string'
import { isoToString } from '@/utils/time'
import {
  reservationById,
  getTripAssignmentsForReservation,
} from '@/services/reservations'
import { tripById } from '@/services/trips'
import { SplitFeatureFlag, VinVerificationType } from '@/utils/enum'

export default {
  components: {
    DispatchReservationSidebarAssignments,
    ReservationSendDriverInfo,
    StopInfoSidebar,
    ReservationAddTripNotes,
    AddContactNote,
    TripContactSidebar,
  },
  provide: {
    isInDispatchSidebar: true,
  },
  props: {
    parentReservationId: {
      type: Number,
      default: null,
    },
    reservationId: {
      type: Number,
      default: undefined,
    },
    managedId: {
      type: String,
      default: undefined,
    },
    referral: {
      type: Object,
      default: undefined,
    },
    drivers: {
      type: Array,
      default: () => [],
    },
    vehicles: {
      type: Array,
      default: () => [],
    },
    loadingDriversVehicles: {
      type: Boolean,
      default: false,
    },
    isAssignmentsOnly: {
      type: Boolean,
    },
    companyId: {
      type: Number,
      default: undefined,
    },
  },
  data: () => ({
    reservation: undefined,
    loading: true,
    tripAssignments: [],
    model: [],
    assignmentsToUpdate: [],
    availableDrivers: [],
    availableVehicles: [],
    debounce: undefined,
    isDriverVehicleDecouplingEnabled: false,
    originalAssignments: []
  }),
  computed: {
    ...authComputed,
    ...mapGetters({
      assignmentsLoading: 'dispatchNew/assignmentsLoading',
      showConflictDetail: 'dispatchNew/showConflictDetail',
      showConfirmationDetail: 'dispatchNew/showConfirmationDetail',
      validateTripLoading: 'dispatchNew/validateTripLoading',
      showSendDriverInfo: 'dispatchNew/showSendDriverInfo',
      showAddStopNote: 'dispatchNew/showAddStopNote',
      showAddContactNote: 'dispatchNew/showAddContactNote',
      showAddTripContactNote: 'dispatchNew/showAddTripContactNote',
      showAddTripContact: 'dispatchNew/showAddTripContact',
      showAddTripNotes: 'dispatchNew/showAddTripNotes',
    }),
    bookedOnDate() {
      const bookedOnISO =
        this.reservation?.quoteCreatedOn || this.reservation?.createdOn
      return isoToString(bookedOnISO)
    },
    customerNotesExist() {
      return (
        this.reservation.trip.customer.customerNotes?.[0]?.html ||
        this.reservation.trip.customer.customerNotes?.[0]?.note ||
        this.reservation.trip.customer.notes
      )
    },
    reservationCompanyId() {
      if (this.companyId) {
        return this.companyId
      }

      if (this.reservation?.companyId) {
        return this.reservation.companyId
      }

      return this.currentUser?.companyId
    },
    reservationIsNotReferral() {
      return this.reservation?.reservationType !== 1
    },
    conflicts() {
      let conflicts = []
      this.model.map((group) => {
        group.vehicleAssignments.map(async (assignment) => {
          if (assignment.vehicle?.hardConflict) {
            let conflict = {
              name: assignment.vehicle?.vehicleFullName,
              vehicleId: assignment.vehicle?.vehicleId,
              conflictType: 'hard',
              conflictHeader: 'Double Booked',
              description:
                'This vehicle has been assigned to a reservation that overlaps with the timing of this reservation.',
            }
            conflicts.push(conflict)
          }

          assignment.driverAssignments.map((driverAssignment) => {
            if (driverAssignment.driver?.hardConflict) {
              conflicts.push({
                name: `${driverAssignment.driver?.firstName} ${driverAssignment.driver?.lastName}`,
                driverId: driverAssignment.driver?.driverId,
                conflictType: 'hard',
                conflictHeader: 'Double Booked',
                description:
                  'This driver has been assigned to a reservation that overlaps with the timing of this reservation.',
              })
            }
          })
        })
      })

      return conflicts
    },
    balanceIsPaid() {
      if (this.reservation?.balance == null) return false
      return this.reservation.balance <= 0
    },
    dueDateIsPast() {
      if (!this.reservation.dueDate) return false

      let now = DateTime.local()
      let dueDate = DateTime.fromISO(this.reservation.dueDate)

      if (now.hasSame(dueDate, 'day')) {
        return false
      }

      return dueDate < now
    },
    dueDateError() {
      return !this.balanceIsPaid && this.dueDateIsPast
    },
    trip() {
      if (this.isAssignmentsOnly) {
        let trip = { ...this.reservation }
        if (this.reservation.trip) {
          trip = { ...this.reservation.trip, ...trip }
        }
        return trip
      } else {
        return this.reservation?.trip
      }
    },
    reservationButtonIsDisabled() {
      if (this.assignmentsLoading || this.hasInvalidAssignment) {
        return true
      }

      if (this.assignmentsToUpdate.length) {
        return false
      }

      return false
    },
    reservationCompanyEmail() {
      return this.reservation?.trip?.customer?.email
    },
    reservationTripContactEmail() {
      return this.reservation?.trip?.tripContact?.email
    },
    showReservationDetail() {
      return (
        !this.showConflictDetail &&
        !this.showConfirmationDetail &&
        !this.showSendDriverInfo &&
        !this.showAddStopNote &&
        !this.showAddContactNote &&
        !this.showAddTripContactNote &&
        !this.showAddTripContact &&
        !this.showAddTripNotes
      )
    },
    hasInvalidAssignment() {
      return this.model.some(group => group.vehicleAssignments.some(va => va?.vehicle?.vinVerificationType?.key == VinVerificationType.VerificationFailure))
    }
  },
  watch: {
    managedId: {
      immediate: true,
      handler: async function (newReservationId) {
        if (!newReservationId) return

        const newReservation = await this.load()
        let {
          vehicleAssignments,
          requiredVehicles,
          requiredDrivers,
          tripVehicleGroups,
        } = newReservation

        this.model = buildModel(
          vehicleAssignments,
          requiredVehicles,
          requiredDrivers,
          tripVehicleGroups
        )
      },
    },
    conflicts: {
      immediate: true,
      handler: async function (conflicts) {
        await Promise.all(
          conflicts.map(async (conflict) => {
            const conflictingReservation = await this.getConflictingReservation(
              conflict
            )
            conflict.conflictingReservationManagedId =
              conflictingReservation.managedId
            conflict.conflictingReservationId =
              conflictingReservation.reservationId
            conflict.customerName = conflictingReservation.customerName
            conflict.customerAccountName =
              conflictingReservation.customerAccountName
          })
        )
      },
    },
    drivers: {
      deep: true,
      handler() {
        this.resetSidebar()
      },
    },
    vehicles: {
      deep: true,
      handler() {
        this.resetSidebar()
      },
    },
  },
  async mounted() {
    EventBus.$on('dispatch-refreshed', () => {
      this.resetSidebar()
    })
    EventBus.$on('customer-notes-update', (note, html) => {
      if (this.reservation.trip?.customer?.customerNotes?.[0]) {
        this.reservation.trip.customer.customerNotes[0].html = html
        this.reservation.trip.customer.customerNotes[0].note = note
      } else {
        this.reservation.trip.customer.customerNotes = []
        this.reservation.trip.customer.customerNotes.push({
          note: note,
          html: html,
        })
      }
    })
    EventBus.$on('trip-contact-notes-update', (note, html) => {
      if (this.reservation.trip?.tripContact?.notes?.[0]) {
        this.reservation.trip.tripContact.notes[0].html = html
        this.reservation.trip.tripContact.notes[0].note = note
      } else {
        this.reservation.trip.tripContact.notes = []
        this.reservation.trip.tripContact.notes.push({
          note: note,
          html: html,
        })
      }
    })
    EventBus.$on('trip-contact-update', (tripContact) => {
      this.reservation.trip.tripContact = tripContact
    })
    this.isDriverVehicleDecouplingEnabled = await this.isFeatureEnabled(
      SplitFeatureFlag.DriverVehicleDecoupling
    )
  },
  methods: {
    ...mapActions({
      showAlert: 'app/showAlert',
      setLoading: 'dispatchNew/setLoading',
      setShowConflictDetail: 'dispatchNew/setShowConflictDetail',
      setShowConfirmationDetail: 'dispatchNew/setShowConfirmationDetail',
      setShowSendDriverInfo: 'dispatchNew/setShowSendDriverInfo',
      setShowAddStopNote: 'dispatchNew/setShowAddStopNote',
      setShowAddContactNote: 'dispatchNew/setShowAddContactNote',
      setShowAddTripContactNote: 'dispatchNew/setShowAddTripContactNote',
      setShowAddTripContact: 'dispatchNew/setShowAddTripContact',
      setShowAddTripNotes: 'dispatchNew/setShowAddTripNotes',
      isFeatureEnabled: 'split/isFeatureEnabled',
    }),
    phoneFormatFilter,
    currencyFilter,
    toTitleCase,
    isoToString,
    clickContactNote() {
      this.setShowAddContactNote(true)
    },
    clickTripContactNote() {
      this.setShowAddTripContactNote(true)
    },
    clickTripContact() {
      this.setShowAddTripContact(true)
    },
    async loadVehicles() {
      if (!this.reservationId) {
        return
      }

      if (!this.reservationCompanyId) {
        return
      }

      let vehicleResponse = await getAvailableVehiclesForRes(
        [this.reservationId],
        this.reservationCompanyId
      )

      let availableVehicles = vehicleResponse.data.vehicles
      let assignedVehicles = this.getAssignedVehicles(this.reservation)
      availableVehicles = [...availableVehicles, ...assignedVehicles]
      let mappedVehicles = this.vehicles.map((vehicle) => {
        let hardConflict = !availableVehicles.some(
          (v) => v.vehicleId === vehicle.vehicleId
        )
        return { ...vehicle, hardConflict }
      })

      this.availableVehicles = mappedVehicles
    },
    async loadDrivers() {
      if (!this.reservationId) {
        return
      }

      let driverResponse = await getAvailableDriversForRes(
        [this.reservationId],
        this.reservationCompanyId
      )

      let availableDrivers = driverResponse.data.drivers

      let assignedDrivers = this.getAssignedDrivers(this.reservation)

      availableDrivers = [...availableDrivers, ...assignedDrivers]

      let mappedDrivers = this.drivers.map((driver) => {
        let hardConflict = !availableDrivers.some(
          (d) => d.userId === driver.userId
        )
        return { ...driver, hardConflict }
      })

      this.availableDrivers = mappedDrivers
    },
    updateModel(payload) {
      let { assignment, index, groupIndex } = payload
      this.model[groupIndex].vehicleAssignments[index] = assignment

      this.assignmentsToUpdate = this.assignmentsToUpdate.filter(
        (assignment) =>
          !(assignment.index === index && assignment.groupIndex === groupIndex)
      )
      this.assignmentsToUpdate.push({ ...assignment, index, groupIndex })
    },
    async load() {
      this.loading = true
      const tripAssignmentPayload = {
        reservationIds: [this.reservationId],
      }
      const [res, tripAssignmentRes] = await Promise.all([
        reservationById(this.managedId),
        getTripAssignmentsForReservation(tripAssignmentPayload),
      ])
      let reservation = res.data

      if (
        reservation.reservationId === null &&
        typeof this.referral !== 'undefined'
      ) {
        reservation = this.referral
        if (!reservation.tripId) {
          reservation.tripId = reservation.trip?.tripId
        }
      }
      const { tripId } = reservation

      reservation.vehicleAssignments = tripAssignmentRes.data.vehicleAssignments
      this.originalAssignments = deepClone(tripAssignmentRes.data.vehicleAssignments)

      const tripRes = await tripById(tripId)
      reservation.trip = tripRes.data

      this.reservation = reservation

      await Promise.all([this.loadDrivers(), this.loadVehicles()])
      this.loading = false
      return reservation
    },
    async validateReservationUpdates() {
      const tripAssignmentPayload = {
        reservationIds: [this.reservationId],
      }
      const tripAssignmentRes = await getTripAssignmentsForReservation(tripAssignmentPayload)
      const tripAssignments = tripAssignmentRes.data.vehicleAssignments

      if (tripAssignments.length !== this.originalAssignments.length) {
        this.showAlert({
          type: 'error',
          message: 'Assignments have changed since opening edit assignments. Please reload the page before reassigning.',
        })
        return
      }

      for (let i = 0; i < tripAssignments.length; i++) {
        if (tripAssignments[i].vehicleId !== this.originalAssignments[i].vehicleId || tripAssignments[i].driverAssignments?.length !== this.originalAssignments[i].driverAssignments?.length) {
          this.showAlert({
            type: 'error',
            message: 'Assignments have changed since opening edit assignments. Please reload the page before reassigning.',
          })
          return
        }
        for (let j = 0; j < tripAssignments[i].driverAssignments.length; j++) {
          if (tripAssignments[i].driverAssignments[j].userId !== this.originalAssignments[i].driverAssignments[j].userId) {
            this.showAlert({
              type: 'error',
              message: 'Assignments have changed since opening edit assignments. Please reload the page before reassigning.',
            })
            return
          }
        }
      }

      try {
        if (!this.isDriverVehicleDecouplingEnabled) {
          // Check that all drivers are assigned with a vehicle
          this.assignmentsToUpdate.map((va) => {
            va.driverAssignments.map((da) => {
              if (da?.driver?.userId && !va?.vehicle?.vehicleId) {
                throw { message: 'All Drivers Must Be Assigned With a Vehicle' }
              }
            })
          })
        }

        // Check that duplicate drivers are not assigned
        let driverIds = []
        this.model.map((group) => {
          group.vehicleAssignments.map((va) => {
            let { driverAssignments } = va
            driverAssignments = driverAssignments
              .filter((d) => !!d.driver?.userId)
              .map((d) => d?.driver?.userId)
            const intersection = driverAssignments.filter((d) =>
              driverIds.includes(d)
            )
            if (intersection.length > 0) {
              throw {
                message:
                  'The same driver has been assiged to more than one vehicle.',
              }
            } else {
              driverIds = [...driverAssignments, ...driverIds]
            }
          })
        })

        // Check duplicate vehicles have not been assigned
        let vehicleIds = []
        this.model.map((group) => {
          group.vehicleAssignments.map((va) => {
            if (va?.vehicle?.vehicleId) {
              const intersection = vehicleIds.includes(va.vehicle.vehicleId)
              if (intersection) {
                throw {
                  message:
                    'One vehicle has been assigned to multiple assignments',
                }
              } else {
                vehicleIds = [...vehicleIds, va.vehicle.vehicleId]
              }
            }
          })
        })
      } catch (e) {
        this.showAlert({
          type: 'error',
          message: e.response?.data?.message || e.message,
        })
        return
      }

      // Check that there aren't any drivers or vehicles
      // assigned with hard conflicts
      if (this.conflicts.length > 0) {
        this.setShowConfirmationDetail(true)
        return
      }

      this.commitReservationUpdates()
    },
    resetSavedReservation() {
      setTimeout(() => {
        this.showAlert({
          type: 'success',
          message: `Assignments for Reservation ${this.reservation.managedId} updated`,
        })
      }, 100)
      this.setShowConfirmationDetail(false)
      this.setLoading({ assignmentsLoading: false })
      EventBus.$emit('saved-reservation')
      this.$emit('refresh')
      if (this.isAssignmentsOnly) {
        EventBus.$emit('global-table-view-refresh')
        EventBus.$emit('refresh-reservation-detail')
      }
      this.assignmentsToUpdate = []
      this.load()
      return
    },
    closeSidebar() {
      this.resetSidebar()
      this.$emit('close')
    },
    resetSidebar() {
      this.setLoading({ assignmentsLoading: false, validateTripLoading: false })
      this.setShowConfirmationDetail(false)
      this.resetAvailableDrivers()
      this.resetAvailableVehicles()
      this.assignmentsToUpdate = []
    },
    async createSingleAssignment(vehicleAssignment, stopIds) {
      let payload = {
        ...stopIds,
        vehicleId: vehicleAssignment.vehicle.vehicleId,
        vehicleTypeId: vehicleAssignment.vehicleTypeId,
        tripVehicleGroupId: vehicleAssignment.tripVehicleGroupId,
        driverAssignments: [],
        hardConflictOverride: true,
      }

      // Populate the driver assignment for each new assignment
      vehicleAssignment.driverAssignments.map((driverAssignment) => {
        if (driverAssignment?.driver?.userId) {
          payload.driverAssignments.push({
            userId: driverAssignment?.driver?.userId,
            hardConflictOverride: true,
            ...stopIds,
          })
        }
      })

      return createTripAssignment(payload)
    },
    async createMultipleAssignments(vehicleAssignments, stopIds) {
      const assignmentResults = []

      for (const vehicleAssignment of vehicleAssignments) {
        const assignmentResult = await this.createSingleAssignment(vehicleAssignment, stopIds)
        assignmentResults.push(assignmentResult)
      }

      return assignmentResults
    },
    async commitReservationUpdates() {
      try {
        this.setLoading({ assignmentsLoading: true })
        let stopIds = this.getFirstAndLastStops(this.reservation)

        // Delete any assignments that we need to update
        let assignmentsToDelete = []
        this.assignmentsToUpdate
          .filter((va) => !!va.vehicleAssignmentId)
          .map((vehicleAssignment) => {
            let assignmentIds = this.getTripAssignmentIds(vehicleAssignment)
            assignmentsToDelete = [...assignmentsToDelete, ...assignmentIds]
          })

        if (assignmentsToDelete.length) {
          let deletionResponse = await deleteTripAssignmentsWithIds(
            assignmentsToDelete
          )
          if (!deletionResponse.data.successful) {
            throw deletionResponse
          }
        }

        // Create list of new assignments to create
        let assignmentsToCreate
        if (this.isDriverVehicleDecouplingEnabled) {
          assignmentsToCreate = this.assignmentsToUpdate.filter((va) => {
            const isVehicleAssigned = !!va.vehicle?.vehicleId
            const validDriverAssignments = va.driverAssignments.filter(
              (da) => !!da.driver?.driverId
            )
            const hasValidDriverAssignments = validDriverAssignments.length > 0
            return isVehicleAssigned || hasValidDriverAssignments
          })
        } else {
          assignmentsToCreate = this.assignmentsToUpdate.filter(
            (va) => !!va.vehicle?.vehicleId
          )
        }

        if (assignmentsToCreate.length < 1) {
          this.resetSavedReservation()
          return
        }

        try {
          const results = await this.createMultipleAssignments(
            assignmentsToCreate,
            stopIds
          )

          let parentReservationId
          const successfulAssignmentsWithNewDrivers = results?.some(
            (response) => {
              parentReservationId = response?.data?.parentReservationId
              return (
                response?.status == 200 &&
                response?.data?.driverAssignmentList.length > 0
              )
            }
          )

          if (successfulAssignmentsWithNewDrivers && !!parentReservationId) {
            sendDriverInfoNotifications(parentReservationId)
          }
        } catch (e) {
          this.showAlert({ type: 'error', message: e })
        }
        this.resetSavedReservation()
      } catch (e) {
        console.log(e)
        this.showAlert({
          type: 'error',
          message: e.response?.data?.message || e.message,
        })
        this.setLoading({ assignmentsLoading: false })
      }
    },

    isoToSimpleString(date) {
      let dateString = DateTime.fromISO(date)
      return dateString.toFormat('M/dd/yyyy')
    },
    getAssignedVehicles() {
      if (!this.reservation?.vehicleAssignments) {
        return
      }
      return this.reservation.vehicleAssignments.map(
        (vehicleAssignment) => vehicleAssignment.vehicle
      )
    },
    getAssignedDrivers() {
      let drivers = []
      if (!this.reservation?.vehicleAssignments) return
      this.reservation.vehicleAssignments.map(({ driverAssignments }) => {
        driverAssignments.map(({ driver }) => {
          drivers.push(driver)
        })
      })
      return drivers
    },
    getFirstAndLastStops(reservation) {
      let { stops } = this.trip
      if (!stops?.length) return {}
      let payload = {
        reservationId: reservation.reservationId,
      }

      let firstStop = stops[0]
      let lastStop = stops[stops.length - 1]

      if (firstStop?.address?.addressId) {
        firstStop.addressId = firstStop.address.addressId
      }

      if (lastStop?.address?.addressId) {
        lastStop.addressId = lastStop.address.addressId
      }

      let firstStopId = firstStop.stopId
      let lastStopId = lastStop.stopId

      // eslint-disable-next-line no-extra-boolean-cast
      payload = { ...payload, firstStopId, firstStop, lastStopId, lastStop }

      return payload
    },

    // From an assignment, return an array of all tripAssignmentIds associated
    // with that assignment
    getTripAssignmentIds(assignment) {
      let ids = []

      if (assignment.vehicleAssignmentId) {
        ids.push(assignment.vehicleAssignmentId)
      }

      assignment.driverAssignments.map((driverAssignment) => {
        if (driverAssignment.tripAssignmentId) {
          ids.push(driverAssignment.tripAssignmentId)
        }
      })

      return ids
    },
    async resetAvailableDrivers() {
      if (!this.reservation?.reservationId) return
      let driverResponse = await getAvailableDriversForRes(
        [this.reservation.reservationId],
        this.reservationCompanyId
      )

      let availableDrivers = driverResponse.data.drivers

      let assignedDrivers = this.getAssignedDrivers(this.reservation)

      availableDrivers = [...availableDrivers, ...assignedDrivers]

      let mappedDrivers = this.drivers.map((driver) => {
        let hardConflict = false
        if (availableDrivers.some((d) => d.userId === driver.userId)) {
          hardConflict = false
        } else {
          hardConflict = true
        }

        return { ...driver, hardConflict }
      })

      this.model.map((group) => {
        group.vehicleAssignments.forEach((vehicleAssignment) => {
          if (vehicleAssignment?.vehicle?.vehicleId) {
            let vehicleId = vehicleAssignment?.vehicle?.vehicleId
            vehicleAssignment.vehicle = this.availableVehicles.find(
              (v) => v.vehicleId === vehicleId
            )
          }

          vehicleAssignment.driverAssignments.forEach((driverAssignment) => {
            if (driverAssignment?.driver?.userId) {
              let userId = driverAssignment?.driver?.userId
              driverAssignment.driver = this.availableDrivers.find(
                (d) => d.userId === userId
              )
            }
          })
        })
      })

      this.availableDrivers = mappedDrivers
    },
    async resetAvailableVehicles() {
      if (!this.reservation?.reservationId) return
      let vehicleResponse = await getAvailableVehiclesForRes(
        [this.reservation.reservationId],
        this.reservationCompanyId
      )

      let availableVehicles = vehicleResponse.data.vehicles
      let assignedVehicles = this.getAssignedVehicles(this.reservation)
      availableVehicles = [...availableVehicles, ...assignedVehicles]
      let mappedVehicles = this.vehicles.map((vehicle) => {
        let hardConflict = false
        if (availableVehicles.some((v) => v.vehicleId === vehicle.vehicleId)) {
          hardConflict = false
        } else {
          hardConflict = true
        }

        return { ...vehicle, hardConflict }
      })

      this.availableVehicles = mappedVehicles
    },

    displayReservationInfo() {
      this.setShowConfirmationDetail(false)
      this.setShowConflictDetail(false)
      this.setShowSendDriverInfo(false)
      this.setShowAddStopNote(false)
      this.setShowAddContactNote(false)
      this.setShowAddTripContactNote(false)
      this.setShowAddTripNotes(false)
    },
    async getConflictingReservation(conflict) {
      if (conflict.vehicleId) {
        let response = await getUnavailableVehiclesForRes(
          [this.reservation.reservationId],
          this.reservationCompanyId
        )
        let vehicleConflictData = response.data
        let conflictingAssignment = vehicleConflictData.vehicleConflicts.find(
          (vehicleConflict) => vehicleConflict.vehicleId === conflict.vehicleId
        )
        const conflictingReservation = {
          managedId: conflictingAssignment.reservationManagedId,
          reservationId: conflictingAssignment.reservationId,
          customerName: conflictingAssignment.customerName,
          customerAccountName: conflictingAssignment.customerAccountName,
        }
        return conflictingReservation
      } else {
        let response = await getUnavailableDriversForRes(
          [this.reservation.reservationId],
          this.reservationCompanyId
        )
        let driverConflictData = response.data
        let conflictingAssignment = driverConflictData.driverConflicts.find(
          (driverConflict) => driverConflict.driverId === conflict.driverId
        )
        const conflictingReservation = {
          managedId: conflictingAssignment.reservationManagedId,
          reservationId: conflictingAssignment.reservationId,
          customerName: conflictingAssignment.customerName,
          customerAccountName: conflictingAssignment.customerAccountName,
        }
        return conflictingReservation
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.dispatch-sidebar {
  display: flex;
  flex-direction: column;
  width: 500px;
  background-color: white;

  h4 {
    font-size: 24px;
  }

  p {
    margin: 0;
  }

  &--confirmation-conflict-box {
    h4 {
      font-weight: bold;
      font-size: 19px;
      margin-left: 60px;
    }

    p {
      margin-left: 5px;
    }
  }

  &--confirmation-description-header {
    font-weight: bold;
    margin-left: 5px;
  }

  &--conflict-box {
    h4 {
      font-weight: bold;
      font-size: 19px;
      margin-left: 38px;
    }
  }

  &--conflict-details {
    display: flex;

    .cr-icon {
      min-width: 38px;
      padding-right: 10px;
    }

    p {
      color: $gray;
    }
  }

  &--conflict-description-header {
    font-weight: bold;
  }

  &--conflict-arrow {
    a {
      display: flex;
      align-items: center;

      .cr-icon {
        margin-left: 4px;
        margin-right: 18px !important;
      }
    }
    margin-top: -10px;
    margin-bottom: 15px;
  }

  &--gray-text {
    color: $dispatch-gray;
  }

  &--error-box {
    margin-bottom: 20px;

    &-content {
      background-color: $error-pale;
      padding: 20px 10px;
      border-radius: 10px;

      p {
        padding: 0 5px 0 15px;
      }
    }
  }

  &--header-small {
    color: $dispatch-gray;
    font-size: 12px;
  }

  &--reservation-info-content {
    flex: 1;
  }

  &--reservation-info-content > div {
    margin: 40px;
  }

  &--conflict-wrapper > div {
    margin: 40px;
  }

  &--customer-info-box {
    background: $dispatch-off-white;
    padding: 15px 20px;
    border-radius: 5px;
    transition: 0.1s opacity ease-in-out;
  }

  &--error-text {
    color: $error;
  }

  &--due-date {
    text-transform: uppercase;
    background: $success;
    color: white;
    font-size: 10px;
    padding: 2px 6px;
    border-radius: 3px;
    margin-left: 5px;
  }

  &--due-date-past {
    background: $error;
  }

  &--trip-total {
    padding-bottom: 30px;
    border-bottom: 1px solid $dispatch-gray;
    margin: 40px 40px 0 40px;
  }

  &--button-spacer {
    min-height: 71px;
    width: 100%;
  }

  &--assignments {
    margin: 0 !important;
    padding: 15px 40px;
  }

  &--action-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    font-size: 18px;
    bottom: 0;
    width: 500px !important;
    height: 71px !important;
    padding: 24px 0;
    width: inherit;
    border-radius: 0;
  }

  &--confirmation-content {
    margin: 20px 40px;
  }

  &--confirmation-cancel {
    bottom: 71px;
    border: none !important;
  }
}

.edit-button {
  cursor: pointer;
  font-weight: bold;
  color: $primary;
  font-size: 13px;
  padding: 7px;
}

::v-deep {
  .v-timeline-item {
    padding-top: 7px;
  }

  .v-timeline-item__dot {
    height: 9px;
    width: 9px;
    left: 0px;
    box-shadow: none;
  }

  .v-timeline-item__inner-dot {
    height: 9px;
    width: 9px;
  }

  .v-timeline:before {
    background: transparent;
    border: none;
    border-right: 1px dashed black;
    top: 22px;
    height: calc(100% - 54px);
  }

  .v-timeline--dense:before {
    left: 3px;
  }

  .v-timeline {
    padding-top: 0;
  }

  .v-timeline-item__body {
    max-width: calc(100% - 21px);
  }

  .v-input__slot {
    background-color: $blue-light !important;
    border: 1px solid $input-border-gray !important;
  }

  .theme--light.v-select .v-select__selections {
    color: $gray-base !important;
  }

  .theme--dark.v-select .v-select__selections {
    color: $gray-base !important;
  }

  .theme--dark input {
    color: $gray-base !important;
  }
}

.primary-text {
  color: $primary;
}

.sidebar-link {
  color: $gray-dark;
  text-decoration: none;

  &:hover .dispatch-sidebar--customer-info-box {
    opacity: 0.8;
  }
}

.arrow_back {
  color: $black;
  height: 24px;
  margin-right: 8px;
}

.validate-trip-loading {
  color: $gray-medium-light;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  padding-bottom: 8px;

  span {
    margin-right: 10px;
    margin-bottom: -5px;
  }

  .dot-typing {
    position: relative;
    left: -9999px;
    width: 2px;
    height: 2px;
    border-radius: 2px;
    background-color: $gray-medium-light;
    color: $gray-medium-light;
    box-shadow: 9992px 0 0 0 $gray-medium-light, 9999px 0 0 0 $gray-medium-light,
      10006px 0 0 0 $gray-medium-light;
    animation: dotTyping 1.5s infinite linear;
  }
}

@keyframes dotTyping {
  0% {
    box-shadow: 9992px 0 0 0 $gray-medium-light, 9999px 0 0 0 $gray-medium-light,
      10006px 0 0 0 $gray-medium-light;
  }
  16.667% {
    box-shadow: 9992px -5px 0 0 $gray-medium-light,
      9999px 0 0 0 $gray-medium-light, 10006px 0 0 0 $gray-medium-light;
  }
  33.333% {
    box-shadow: 9992px 0 0 0 $gray-medium-light, 9999px 0 0 0 $gray-medium-light,
      10006px 0 0 0 $gray-medium-light;
  }
  50% {
    box-shadow: 9992px 0 0 0 $gray-medium-light,
      9999px -5px 0 0 $gray-medium-light, 10006px 0 0 0 $gray-medium-light;
  }
  66.667% {
    box-shadow: 9992px 0 0 0 $gray-medium-light, 9999px 0 0 0 $gray-medium-light,
      10006px 0 0 0 $gray-medium-light;
  }
  83.333% {
    box-shadow: 9992px 0 0 0 $gray-medium-light, 9999px 0 0 0 $gray-medium-light,
      10006px -5px 0 0 $gray-medium-light;
  }
  100% {
    box-shadow: 9992px 0 0 0 $gray-medium-light, 9999px 0 0 0 $gray-medium-light,
      10006px 0 0 0 $gray-medium-light;
  }
}
</style>
