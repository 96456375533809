<template>
  <div class="send-driver-info">
    <div class="send-driver-info--content">
      <div v-show="inDispatchSidebar" class="send-driver-info--back-arrow">
        <a class="arrow_back" @click="setShowSendDriverInfo(false)">
          <CRIcon view-box="0 0 24 24" :width="20" :height="20">
            arrow_back
          </CRIcon>
          Back To Reservation
        </a>
      </div>
      <CRForm ref="driver-form">
        <div>
          <v-layout
            v-for="(row, i) in rows"
            :key="`driver-info-row-${i}`"
            class="send-driver-info--email-row"
            row
          >
            <v-flex xs11>
              <CRTextField
                v-model="models[row.model]"
                class="send-driver-info--input"
                :rules="[checkEmail]"
                background-color="inputBackgroundGray"
                validate-on-blur
                @change="force"
              />
            </v-flex>
            <v-flex xs1 class="trash">
              <v-btn v-if="i !== 0" icon @click="(evt) => removeRow(row.model)">
                <CRIcon color="error">trash</CRIcon>
              </v-btn>
            </v-flex>
          </v-layout>
        </div>
        <div>
          <button
            id="send-driver-info-add-recipient-button"
            class="send-driver-info--link-btn"
            @click="(e) => addRow(e)"
          >
            + Add Recipient
          </button>
        </div>
      </CRForm>
    </div>
    <div class="cr-sidebar-dialog--btn-spacer"></div>
    <div
      id="reservation-driver-info-send-button"
      class="cr-sidebar-dialog--action-btn"
      @click="send"
    >
      <span v-if="!loading">Send</span>
      <CRProgressCircular v-else :size="27" />
    </div>
  </div>
</template>
<script>
import uuid from 'uuid'
import { mapGetters, mapActions } from 'vuex'

const checkEmail = (value) => {
  const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return pattern.test(value) || 'Invalid e-mail.'
}
export default {
  props: {
    customerEmail: {
      type: String,
      default: '',
    },
    tripContactEmail: {
      type: String,
      default: '',
    },
    reservationId: {
      type: Number,
      default: null,
    },
    reservationList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      models: {},
      rows: [],
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      showSendDriverInfo: 'dispatchNew/showSendDriverInfo',
    }),
    inDispatchSidebar() {
      return this.showSendDriverInfo
    },
  },
  mounted() {
    const id = uuid.v4()
    this.models[id] = this.customerEmail
    this.rows.push({
      model: id,
    })
    if (this.tripContactEmail && this.customerEmail !== this.tripContactEmail) {
      const id2 = uuid.v4()
      this.models[id2] = this.tripContactEmail
      this.rows.push({
        model: id2,
      })
    }
  },
  methods: {
    ...mapActions({
      setShowSendDriverInfo: 'dispatchNew/setShowSendDriverInfo',
    }),
    checkEmail,
    checkStatus() {
      return !Object.keys(this.models).every((id) => {
        return typeof checkEmail(this.models[id]) !== typeof 'string'
      })
    },
    force() {
      this.$forceUpdate()
    },
    addRow(e) {
      e.preventDefault()

      const id = uuid.v4()
      this.models[id] = null
      this.rows.push({
        model: id,
      })
    },
    removeRow(id) {
      delete this.models[id]
      this.rows = this.rows.filter((m) => m.model !== id)
      this.$forceUpdate()
    },
    async sendDriverInfo(reservationId, tos) {
      const payload = { tos }
      try {
        await this.$store.dispatch('reservations/sendDriverInfo', {
          reservationId,
          payload,
        })
      } catch (e) {
        this.$store.dispatch(
          'app/showAlert',
          { message: 'An error has occurred.' },
          { root: true }
        )
        this.loading = false
        throw e
      }
    },
    async send() {
      this.loading = true

      const formIsValid = this.$refs['driver-form'].validate()
      if (!formIsValid) {
        this.loading = false
        return
      }

      const tos = [...Object.values(this.models)]

      if (this.reservationList.length == 0) {
        await this.sendDriverInfo(this.reservationId, tos)
      } else {
        for (const reservation of this.reservationList) {
          const reservationId = reservation.item ? reservation.item.reservationId : reservation?.reservationId
          if (reservationId) {
            await this.sendDriverInfo(reservationId, tos)
          }
        }
      }

      this.loading = false
      if (this.inDispatchSidebar) {
        this.setShowSendDriverInfo(false)
      } else {
        this.close()
      }
      setTimeout(() => {
        this.$store.dispatch('app/showAlert', {
          color: 'success',
          message: 'Driver Information has been sent!',
        })
      }, 100)
    },
    close() {
      this.$store.dispatch('app/closeDialog')
    },
  },
}
</script>

<style lang="scss" scoped>
.send-driver-info {
  height: 100%;
  width: 500px;

  &--content {
    flex: 1;
    margin: 40px;
  }

  &--back-arrow {
    a {
      display: flex;
      align-items: center;

      .cr-icon {
        margin-left: 4px;
        margin-right: 18px !important;
      }
    }
    margin-top: -10px;
    margin-bottom: 15px;
  }

  &--email-row {
    margin: 10px 0;
  }

  &--link-btn {
    color: $primary;
  }
}

.arrow_back {
  color: $black;
  height: 24px;
  margin-right: 8px;
}
</style>
